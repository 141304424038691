import React, { useState, useEffect } from "react";
import "./Home.css";
import { MDBContainer } from "mdb-react-ui-kit";
import { Card, Button, Form, Col, Row } from "react-bootstrap"
import RangeSlider from 'react-bootstrap-range-slider';
import { LinkContainer } from "react-router-bootstrap";
import { API } from "aws-amplify";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { onError } from "../lib/errorLib";
import LoadingOverlay from "react-loading-overlay";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../lib/contextLib";

export default function Home() {
  const [stockBuyQty, setstockBuyQty] = useState(0);
  const { isAuthenticated } = useAppContext();
  const [sellIncrement, setsellIncrement] = useState(0);
  const [isActive, setisActive] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const [stocktoBuy, setstocktoBuy] = useState(null);
  const [sectorLeaders, setsectorLeaders] = useState([]);

  async function updateSettings(updVal) {
    return API.put("ksstocks", `/settings`, {
      body: updVal
    });

  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Welcome Karthik!</h1>
        <LinkContainer className="font-color" to="/login">
          <Button className="mt-3 format">Login</Button>
        </LinkContainer>

      </div>
    );
  }


  async function updateStockQty(updVal) {
    setstockBuyQty(updVal.stockBuyQty);
    await updateSettings(updVal);
  }

  async function updatesellIncrement(updVal) {
    setsellIncrement(updVal.sellIncrement);
    await updateSettings(updVal);
  }

  function loadSettings() {
    return API.get("ksstocks", "/settings");
  }

  function loadSectorLeaders() {
    return API.get("ksstocks", "/sectors");
  }

  useEffect(() => {

    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        var settings = await loadSettings();
        setstockBuyQty(settings.stockBuyQty);
        setsellIncrement(settings.sellIncrement);

        var sectorLeaders = await loadSectorLeaders();
        setsectorLeaders(sectorLeaders);


      } catch (e) {
        onError("homeonl" + e);
      }


      setisActive(false);

    }

    onLoad();
    
  }, [isAuthenticated]);

  async function handleBuy(event) {

    event.preventDefault();
    setisLoading(true);
    const data = new FormData(event.target);
    console.log("Event:" + data.get('symbol'));
    var symbol = data.get('symbol').toUpperCase();
    if (symbol) {
      var stockData = {
        symbol: symbol,
        qty: stockBuyQty
      }
      await buyStock(stockData);


    }
    setisLoading(false);
  }

  async function buyStock(stockData) {
    setisActive(true);
    await API.post("ksstocks", "/order", {
      body: stockData
    });
    setisActive(false);
    return true;

  }

  return (
    <div className="Home">
      {isAuthenticated ?
        <div className="lander">
          <LoadingOverlay
            active={isActive}
            spinner
            text='Loading...'
          >


            <Row className="row display-flex">
              <Col className="col-md-6 col-xs-6 ">
                <MDBContainer breakpoint="sm" className="panel panel-default px-4 shadow fluid">
                  <h5 className="mb-5">General Settings</h5>

                  <div className="twitterSettings ">
                    <Form>
                      <Form.Group className="labelleft" >
                        <Form.Label>
                          Number of stocks to buy
                        </Form.Label>
                        <Row>
                          <Col sm="8">
                            <RangeSlider
                              min='0'
                              max='10000'
                              step='1000'
                              variant='success'
                              value={stockBuyQty}
                              tooltip='off'
                              onChange={e => setstockBuyQty(e.target.value)}
                              onAfterChange={changeEvent => updateStockQty({ "stockBuyQty": changeEvent.target.value })}
                            />
                          </Col>
                          <Col sm="4">
                            <div>{stockBuyQty}</div>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Form>
                    <Form>
                      <Form.Group className="labelleft">
                        <Form.Label >
                          Auto Sell
                        </Form.Label>
                        <Row>
                          <Col sm="8">
                            <RangeSlider
                              min='0'
                              max='1'
                              step='.05'
                              variant='danger'
                              tooltip='off'
                              value={sellIncrement}
                              onChange={e => setsellIncrement(e.target.value)}
                              onAfterChange={changeEvent => updatesellIncrement({ "sellIncrement": changeEvent.target.value })}
                            />
                          </Col>
                          <Col sm="4">
                            <div>{sellIncrement}</div>
                          </Col>
                        </Row>

                      </Form.Group>
                    </Form>
                  </div>
                </MDBContainer>
              </Col>
              <Col className="col-md-6 col-xs-6 " >
                <MDBContainer breakpoint="sm" className="panel panel-default  px-4 shadow fluid">
                  <h5 className="mb-5">Buy a Stock</h5>
                  <Form onSubmit={handleBuy}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Symbol</Form.Label>
                      <Form.Control className="text-uppercase" name="symbol" type="text" placeholder=" " value={stocktoBuy} onChange={(e) => setstocktoBuy(e.target.value)} />
                    </Form.Group>

                    <LoaderButton
                      block
                      size="md"
                      type="submit"
                      variant="success"
                      isLoading={isLoading}
                      className="mb-5"
                    >
                      Buy
                    </LoaderButton>

                  </Form>
                </MDBContainer>
              </Col>
            </Row>
            <Row className="row mb-3">

              <MDBContainer breakpoint="sm" className="mt-5 mb-4 px-4 shadow fluid">
                <h5 className="mb-5">Sector Leaders</h5>
                <Row xs={1} md={6} className="row display-flex mb-5">
                  {sectorLeaders.length > 0 &&
                    sectorLeaders.map(d => (

                      <Col >
                        <Card key={d.symbols[0]} className="panel panel-default  px-4 shadow fluid  ">
                          <Card.Body>
                            <Card.Title>{d.sk}</Card.Title>
                            <Card.Text>{d.symbols[0]}</Card.Text>
                            <Button variant="success" onClick={() => {
                              buyStock({
                                symbol: d.symbols[0],
                                qty: stockBuyQty
                              })
                            }}>Buy</Button>
                          </Card.Body>
                        </Card>
                      </Col>


                    ))
                  }
                </Row>




              </MDBContainer>

            </Row>
          </LoadingOverlay>
        </div>
        : renderLander()}

    </div>
  );
}